import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { placeOrder } from "./ordersSlice";

type State = {
    deliveryDate?: string;
    orderRef?: string;
    orderSuggestion?: boolean;
    zeroPriceOrder: boolean;
    bypassDeadline: boolean;
    isAdminCalendarPick: boolean;
    bypassProductAvailability: boolean;
};

const initialState: State = {
    zeroPriceOrder: false,
    bypassDeadline: false,
    isAdminCalendarPick: false,
    bypassProductAvailability: false,
};

const orderDetailsSlice = createSlice({
    name: "order-details",
    initialState,
    reducers: {
        setOrderDetails: (
            state,
            {
                payload,
            }: PayloadAction<{
                deliveryDate: string;
                orderRef?: string;
                orderSuggestion: boolean;
                zeroPriceOrder: boolean;
                bypassDeadline: boolean;
                isAdminCalendarPick?: boolean;
                bypassProductAvailability?: boolean;
            }>,
        ) => {
            state.deliveryDate = payload.deliveryDate;
            state.orderRef = payload.orderRef;
            state.orderSuggestion = payload.orderSuggestion;
            state.zeroPriceOrder = payload.zeroPriceOrder;
            state.bypassDeadline = payload.bypassDeadline;
            state.isAdminCalendarPick = Boolean(payload.isAdminCalendarPick);
            state.bypassProductAvailability = Boolean(payload.bypassProductAvailability);
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(placeOrder.fulfilled, state => {
            state.deliveryDate = undefined;
        });
    },
});

export const { setOrderDetails } = orderDetailsSlice.actions;

export const selectDeliveryDate = (state: RootState) => state.orderDetails.deliveryDate;
export const selectOrderRef = (state: RootState) => state.orderDetails.orderRef;
export const selectIsZeroPriceOrder = (state: RootState) => state.orderDetails.zeroPriceOrder;
export const selectBypassDeadline = (state: RootState) => state.orderDetails.bypassDeadline;
export const selectIsAdminCalendarPick = (state: RootState) =>
    state.orderDetails.isAdminCalendarPick;
export const selectIsBypassProductAvailability = (state: RootState) =>
    state.orderDetails.bypassProductAvailability;

export const orderDetailsReducer = orderDetailsSlice.reducer;
