import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { DeliveryDate } from "../types";

export const fetchDeliveryDates = createAsyncThunk("fetch-delivery-dates", async () => {
    return get<DeliveryDate[]>(Endpoint.deliveryDates);
});

type State = {
    loading: boolean;
    error?: string;
};

const adapter = createEntityAdapter<DeliveryDate>({
    selectId: entity => entity.deliveryDate,
});

const slice = createSlice({
    name: "delivery-dates",
    initialState: adapter.getInitialState<State>({
        loading: false,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchDeliveryDates.pending, state => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchDeliveryDates.fulfilled, (state, action) => {
            state.loading = false;
            adapter.setAll(state, action.payload);
        });
        addCase(fetchDeliveryDates.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error.message;
        });
    },
});

export const { selectAll: selectAllDeliveryDates, selectById: selectDeliveryDateById } =
    adapter.getSelectors<RootState>(state => state.deliveryDates);

export const selectDeliveryDatesError = (state: RootState) => state.deliveryDates.error;

export const selectDeliveryDatesLoading = (state: RootState) => state.deliveryDates.loading;

export const deliveryDatesReducer = slice.reducer;
