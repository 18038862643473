import { Locale } from "../assets/locales/locales";

export type AccessToken = {
    accessToken: string;
    maxAge: number;
    permissions: Permission[];
    customerId?: number;
};

export type User = {
    email: string;
    displayName: string;
    userId: number;
    username: string;
    customerId: number;
};

export enum Permission {
    LogInAsCustomer = "log-in-as-customer",
    ViewPrice = "view-price",
}

export type CartItem = {
    salesUnitQuantity: number;
    productType: ProductType;
    productId: string;
};
export enum ItemUnit {
    Kg = "kg",
    Package = "krt",
    Piece = "pcs",
}
export enum ProductType {
    Deli = "deli",
    FoodToGo = "food-to-go",
    SaladBar = "salad-bar",
}

export type ProductId = string;

type ProductImageSizes = {
    60: string;
    120: string;
    640: string;
};

export type Product = {
    productId: string;
    name: string;
    legalName?: string;
    categoryIds: string[];
    types: ProductType[];
    drainedWeight: number;
    warningThreshold: number;
    price?: number;
    vat?: number;
    comparisonPrice?: number;
    // Ignore the property "itemUnit".
    salesUnit: ItemUnit;
    orderMultiple: number;
    isInPlanogram: boolean;
    isFrozen: boolean;
    isNonFood: boolean;
    isFreshCut: boolean;
    isNews: boolean;
    closedInfo?: string;
    imageSrc: ProductImageSizes;
    availableVersions: number[];
    rotationProducts: string[];
    assortments: number[];
};

export type ProductMenuItem = {
    menuProductId: string;
    products: MenuProduct[];
};

type MenuProduct = {
    productId: string;
    quantity: number;
};

export type CartProduct = Product & { type: ProductType; salesUnitQuantity: number };

export type Category = {
    categoryId: string;
    name: string;
};

export type ProductTemperature = {
    module?: number;
    timeMeasured: string;
    temperature: number;
    temperatureCheckedBy: string;
    batchCode?: string;
    productId: string;
    productName: string;
};

export type Assortment = {
    assortmentId: number;
    name: string;
};

export type DeliveryAssortment = {
    assortmentId: number;
    deadline: string;
};

export type DeliveryDate = {
    deliveryDate: string;
    assortments: DeliveryAssortment[];
    isOrderSuggestionAvailable: boolean;
};

export enum FilterType {
    planogram = "planogram",
    all = "all",
    favorites = "favorites",
    accessories = "accessories",
}

export enum SplitType {
    NoSplit = "no-split",
    Frozen = "frozen",
    ProductTypes = "product-types",
}

export enum DeliveryTermType {
    Chilled = "chilled",
    Frozen = "frozen",
    Total = "total",
}

export type DeliveryTerm = {
    type: DeliveryTermType;
    thresholdMin: number;
    thresholdFree: number;
    price: number;
    existingOrderAmount: number;
};

export enum PlanogramStatus {
    Current = "active",
    Future = "future",
    Passed = "passed",
}
export type Planogram = {
    planogramId: number;
    start: string;
    status: PlanogramStatus;
};

export type SupportDetails = {
    phone: string | null;
    email: string | null;
    openingHoursStart: string | null;
    openingHoursEnd: string | null;
    introText: string | null;
};

export enum CustomerContactRole {
    StoreManager = "store-manager",
    DeliResponsible = "deli-responsible",
    FoodToGoResponsible = "food-to-go-responsible",
    SaladBarResponsible = "salad-bar-responsible",
    DeliAlarms = "deli-alarms",
    FoodToGoAlarms = "food-to-go-alarms",
    SaladBarAlarms = "salad-bar-alarms",
}
export type CustomerContact = {
    name: string;
    email: string;
    phone: string;
    role: CustomerContactRole;
};

export type CustomerEmails = {
    orderConfirmationEmails: string[];
    purchaseStatsEmails: string[];
    invoiceEmails: string[];
};

export enum ReplaceOrderProductPolicy {
    ReplaceFreely = "replace-freely",
    ReplaceClosedWithAny = "replace-closed-with-any",
    ReplaceClosedWithRotation = "replace-closed-with-rotation",
}

export type Customer = {
    companyCode: number;
    customerId: number;
    name: string;
    showPrices: boolean;
    currency: string;
    splitWeight: SplitType;
    splitPrice: SplitType;
    hasVat: boolean;
    timeZone: string;
    invoiceAddress: Address;
    deliveryAddress: Address;
    handheldOrderStartDate: string | null;
    canGenerateInventoryReport: boolean;
    canGenerateProductTemperatureReports: boolean;
    planograms: Planogram[];
    groupOrdersOnDeliveryNoteId: boolean;
    hasUser: boolean;
    userEmail?: string;
    emails: CustomerEmails;
    hasDeli: boolean;
    hasSalad: boolean;
    hasFoodToGo: boolean;
    locale: {
        primary: Locale;
        secondary: Locale[];
    };
    onlineClaimAllowed: boolean;
    canClaimFullOrder: boolean;
    showOrderRowWeight: boolean;
    showCommunication: boolean;
    customerClass?: string;
    showPlanogramTabAsDefault: boolean;
    replaceOrderProductPolicy: ReplaceOrderProductPolicy;
    creditFreeze: boolean;
};

export enum OrderStatus {
    NotInJeeves = "not-in-jeeves",
    Created = "created",
    Dispatched = "dispatched",
    Invoiced = "invoiced",
    Canceled = "canceled",
}

export enum ClaimStatus {
    Claimable = "claimable",
    Claimed = "claimed",
    CallCustomerServices = "call-customer-services",
    NotAvailable = "not-available",
}

export type Address = {
    name: string | null;
    street: string | null;
    coAddress: string | null;
    city: string | null;
    postalNo: string | null;
    countryCode: string | null;
};

export type HistoricOrder = {
    orderNo?: number;
    deliveryDate: string;
    reference?: string;
    status: OrderStatus;
    rows: OrderRow[];
    totalWeight: number;
    costs?: Array<{ vatPercent: number; costExVat: number; vatCost: number; costIncVat: number }>;
    timeCreated: string;
    createdBy?: {
        userId?: number;
        displayName: string;
        loggedInAsCustomer?: boolean;
    };
};

export type OrderRow = {
    erpOrderNo?: number;
    productId: string;
    categoryId?: number;
    productName: string;
    legalName?: string;
    vatPercent?: number;
    unitPrice?: number;
    currency?: string;
    orderMultiple: number;
    orderedQuantity: number;
    deliveredQuantity?: number;
    deliveryNoteId?: number;
    claimStatus: ClaimStatus;
    status: OrderStatus;
    invoiceNo?: number;
    subtotal?: number;
    drainedWeight: number;
};

export enum Visibility {
    VisibleMandatory = "visible-mandatory",
    Hidden = "hidden",
    Visible = "visible",
}

export enum ClaimCodeType {
    Order = "order",
    OrderRow = "order-row",
}

export type ClaimCode = {
    claimCodeId: number;
    description: string;
    categoryId?: number;
    bestBefore: Visibility;
    batch: Visibility;
    boxBroken: Visibility;
    info: Visibility;
    image: Visibility;
    quantityHeader?: string;
    type: ClaimCodeType;
};

export enum RequestStatus {
    Fulfilled = "fulfilled",
    Idle = "idle",
    Loading = "loading",
    Rejected = "rejected",
}

export enum AssetCategory {
    instructionVideos = "instructionVideos",
    guidance = "guidance",
    deviationAndClaims = "deviationAndClaims",
    risks = "risks",
    labels = "labels",
    certificatesAndCompliance = "certificatesAndCompliance",
    marcomStoreMaterial = "marcomStoreMaterial",
    marcomStorePosters50x70 = "marcomStorePosters50x70",
    marcomStorePosters70x100 = "marcomStorePosters70x100",
    marcomStorePostersA1 = "marcomStorePostersA1",
    marcomStorePostersA4 = "marcomStorePostersA4",
    marcomCampaignMaterial = "marcomCampaignMaterial",
    marcomCampaignPosters50x70 = "marcomCampaignPosters50x70",
    marcomCampaignPosters70x100 = "marcomCampaignPosters70x100",
    marcomCampaignPostersA1 = "marcomCampaignPostersA1",
    marcomCampaignPostersA4 = "marcomCampaignPostersA4",
    marcomDigitalMaterial = "marcomDigitalMaterial",
    marcomTastings = "marcomTastings",
    marcomNews = "marcomNews",
}
export type AssetType = "video" | "image" | "audio" | "file";
export type Asset = {
    assetId: number;
    category: AssetCategory;
    type: AssetType;
    url: string;
    ext: string;
    name: string;
    mime: string;
};

export type News = {
    newsId: number;
    title: string;
    body: string;
    imageUrl: string | undefined;
    carouselImageUrl: string;
    published: string;
};

export type Message = {
    messageId: number;
    title: string;
    body: string;
    imageUrl: string | undefined;
    urgent: boolean;
    published: string;
    read: boolean;
};

export type LocalizedError = {
    message?: string;
    code?: string;
};
