export const order = {
    addProductsToCart: "Add products to cart",
    availableRotationProducts: "Available replacement",
    banner: "You have an unusually large quantity in your shopping cart. Please verify the quantity for the highlighted items before placing your order.",
    cart: {
        excludingVat: "excluding VAT",
        chilled: "Chilled",
        couldNotDeliverChosenToDate:
            "Marked product(s) could not be delivered to the chosen delivery date. Pick one of the following options:",
        changeDeliveryDate: "Change delivery date",
        containCountProducts: "Your cart contains {{count}} product",
        containCountProducts_plural: "Your cart contains {{count}} products",
        shipping: {
            banner: {
                total: "Shipping",
                frozen: "Frozen",
                chilled: "Chilled",
            },
            free: {
                title: "Free shipping",
                values: {
                    total: "For orders over {{thresholdFree}}",
                    frozen: "Frozen: order > {{thresholdFree}}",
                    chilled: "Chilled: order > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Your order cannot be delivered free of charge because it does not reach the free limit of {{thresholdFree}}. A delivery charge of {{charge}} will be applied. For free delivery, add {{remainingCost}}!",
                belowMin:
                    "Your order does not reach the minimum order of {{thresholdMin}}. Add at least {{remainingCost}} worth of merchandise to unlock delivery!",
            },
            total: {
                total: "Total shipping",
                frozen: "Frozen",
                chilled: "Chilled",
            },
        },
        hideCartProducts: "Hide products in cart",
        frozen: "Frozen",
        placeOrder: "Place order",
        removeProductsThatCannotBeDelivered: "Remove all products that cannot be delivered",
        selectedDeliveryDate: "Selected delivery date",
        showCart: "Show cart",
        showCartProducts: "Show products in cart",
        totalShipping: "Total shipping:",
        title: "Cart",
        vat: "Vat ({{percentage}})",
        weight: {
            "product-types": "Weight Salad Bar / Deli / Food To Go",
            "frozen": "Weight Chilled / Frozen",
            "no-split": "Weight",
        },
    },
    confirmMinThresholdOrderDialog: {
        title: "Confirm",
        body: "Are you sure you want to place an order below the minimum order amount?",
        checkboxLabel: "I confirm that this order is OK",
        yes: "Yes, proceed and place this order",
        no: "No, I do not want to place this order",
    },
    creditFreeze: {
        title: "Important information",
        body:
            "Your store's Picadeli account has unpaid invoices that need to be settled before you can place orders again.\n\n" +
            "Please contact Picadeli Customer Support to resolve the outstanding invoices and get access to ordering again.\n\n" +
            "Call: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Missing delivery calendar",
        title: "Delivery information",
        myDeliveryCalendar: "My delivery calendar",
        orderRef: "Reference: {{orderRef}}",
        orderRefLabel: "Enter reference (optional)",
        selectedDeliveryDate: "Selected delivery date",
        pickDeliveryDate: "Pick delivery date",
        timeLeftToOrder: "Time left to order",
        hideAdvancedOptions: "Hide advanced options",
        showAdvancedOptions: "Show advanced options",
        zeroPriceOrder: "Zero price order",
        adminCalendar: "Admin calendar",
        adminCalendarAnyDeliveryDate: "Bypass delivery calendar (FLS mode)",
        adminCalendarWarning: "Be careful. Only use this option if you know what you're doing!",
        adminCalendarDeliveryDateWarning:
            "OK, time for another warning: If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "Delivery date",
                assortment: "Assortment",
                lastOrderDeadline: "Order deadline",
                onTheWay: "On the way",
            },
            btn: {
                select: "Select",
                selectAndCreateOrderProposal: "Select and create order proposal",
            },
            orderSuggestionAvailable: "Order suggestion available",
        },
    },
    filter: {
        showAllProducts: "All products",
        showPlanogramProducts: "Planogram products",
        showFavorites: "Show favorites",
        showAccessories: "Accessories",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Add order suggestion to cart",
        replaceCartDialog: {
            content:
                "Adding the products in the order suggestion to the shopping cart will cause the existing products to be removed.",
            title: "Replace shopping cart",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Your order has now been received.",
        thankYouForYourPurchase: "Thank you for your purchase",
        yourOrderHasNowBeenReceived: "Your order has now been received",
        continueShopping: "Continue shopping",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Could not place order.",
        removeInvalidProductsBtn: "Remove invalid products",
    },
    planogramPdfBtn: "Planogram PDF",
    productAlreadyInCart: "Already in the cart",
    productAlreadyInList: "Order in product list",
    productHasBeenReplaced: "You have replaced {{productName}}",
    productTable: {
        articleNo: "article no",
        comparisonPrice: "cf. price",
        comparisonPriceNonFood: "cf. price",
        dataSheet: "Data sheet ver. {{version}}",
        icon: {
            frozen: "Frozen",
            inPlanogram: "In planogram",
            orderAssortment: "Order assortment",
            news: "News",
        },
        name: "name",
        onTheWay: "in transit",
        pricePerUnit: "price/unit",
        selectRotationProduct: "Switch",
        selectPriceListProduct: "Select",
        unit: "unit",
    },
    productMenu: {
        orderHelpMeals: "Order help meals",
        orderSuggestionsMeals: "Order suggestion meals",
        orderSuggestionsMealsDescription:
            "Here you can see the amount of each product needed for the number of meals you have chosen. The surplus / deficit column shows how much is left over or missing. NOTE Do not forget items for the bulk sale.",
        pricePerPortion: "Price / Portion",
        numPackages: "Number of packages",
        numPortions: "Number of portions",
        perPortion: "Per portion",
        excessWeightTitle: "Surplus / deficit",
        meals: "Meals",
        mealsDescription:
            "Order the desired number of portions per dish. If you order several meals containing, for example, mashed potatoes, the quantity is added together to minimize possible wastage.",
        createOrderSuggestion: "Create order suggestion",
        someProductsCannotBeDelivered:
            "One or more products in this meal can not be delivered. Click for more information.",
    },
    searchProduct: "Search by article number or name",
    selectAReplacement: "Choose replacement product",
    thresholdWarningDialog: {
        pleaseCheck:
            "Please verify that the quantity is the desired amount before you place your order.",
        title: "An unusually large quantity",
        weHaveNoticed:
            "We've noticed that the quantity of the product(s) below is unusually large:",
    },
    unavailableRotationProducts: "Closed product",
};
