import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getValidCustomerLocale, isValid } from "../../assets/locales/locales";
import { isLoggedInAsCustomer } from "../../network/authService";
import { Endpoint } from "../../network/endpoints";
import { get, post } from "../../network/restClient";
import { translate } from "../../utils/translate";
import { RootState, ThunkApiConfig } from "../configureStore";
import { Customer, CustomerEmails, PlanogramStatus, RequestStatus } from "../types";
import { createUser, selectLocale, setLocale, updateUserEmail } from "./userSlice";

export const fetchCustomer = createAsyncThunk<Customer, void, ThunkApiConfig>(
    "fetch-customer",
    async (_, { dispatch, getState }) => {
        const customer = await get<Customer>(Endpoint.customer);
        const currentLocale = selectLocale(getState());
        const customerLocales = [customer.locale.primary, ...customer.locale.secondary];
        if (!customerLocales.includes(currentLocale)) {
            void dispatch(setLocale(getValidCustomerLocale(customer.locale)));
        }
        return customer;
    },
);

export const updateCustomerEmails = createAsyncThunk(
    "post/customer-emails",
    async (body: CustomerEmails) => {
        await post(Endpoint.emails, body, {
            snackbar: {
                errorMessage: translate("myAccount.updateContactInfo.error"),
                successMessage: translate("myAccount.updateContactInfo.success"),
            },
        });
        return body;
    },
);

export const setLastSelectedDeliveryDate = createAsyncThunk(
    "customer/saveLastSelectedDeliveryDate",
    async (deliveryDate: string) => post(Endpoint.lastSelectedDeliveryDate, { deliveryDate }),
);

type State = {
    loading: boolean;
    error?: string;
    entry?: Customer;
    updateCustomerEmailsRequest: RequestStatus;
};

const initialState: State = {
    loading: false,
    entry: undefined,
    updateCustomerEmailsRequest: RequestStatus.Idle,
};

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(updateCustomerEmails.pending, state => {
            state.updateCustomerEmailsRequest = RequestStatus.Loading;
        });
        addCase(updateCustomerEmails.rejected, state => {
            state.updateCustomerEmailsRequest = RequestStatus.Rejected;
        });
        addCase(updateCustomerEmails.fulfilled, (state, action) => {
            state.updateCustomerEmailsRequest = RequestStatus.Fulfilled;
            if (state.entry) {
                state.entry.emails = action.payload;
            }
        });
        addCase(fetchCustomer.pending, state => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchCustomer.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error.message;
        });
        addCase(fetchCustomer.fulfilled, (state, action) => {
            state.loading = false;
            state.entry = action.payload;
        });

        addCase(createUser.fulfilled, state => {
            if (state.entry) {
                state.entry.hasUser = true;
            }
        });

        addCase(updateUserEmail.fulfilled, (state, action) => {
            if (state.entry && isLoggedInAsCustomer()) {
                state.entry.userEmail = action.meta.arg;
            }
        });
    },
});

export const selectCustomer = (state: RootState) => state.customer.entry;
export const selectUpdateCustomerEmailsRequest = (state: RootState) =>
    state.customer.updateCustomerEmailsRequest;
export const selectCustomerLoading = (state: RootState) => state.customer.loading;
export const selectCustomerError = (state: RootState) => state.customer.error;
export const selectCurrency = (state: RootState) => state.customer.entry?.currency;
export const selectTimeZone = (state: RootState) => state.customer.entry?.timeZone ?? "UTC";
export const selectIsHandheldOrderCustomer = (state: RootState) =>
    !!state.customer.entry?.handheldOrderStartDate;

export const selectCurrentPlanogram = createSelector(
    selectCustomer,
    customer =>
        (customer?.planograms ?? []).filter(({ status }) => status === PlanogramStatus.Current)[0],
);

export const selectCustomerLocales = createSelector(selectCustomer, customer => {
    if (!customer) {
        return [];
    }
    return [customer.locale.primary, ...customer.locale.secondary].filter(l => isValid(l));
});

export const selectFuturePlanograms = createSelector(selectCustomer, customer =>
    (customer?.planograms ?? []).filter(({ status }) => status === PlanogramStatus.Future),
);

export const customerReducer = customerSlice.reducer;
