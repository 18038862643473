export const order = {
    addProductsToCart: "Lägg produkter i varukorg",
    availableRotationProducts: "Tillgängligt byte",
    banner: "Du har ovanligt stort antal i varukorgen. Vänligen kontrollera att antalet för de markerade produkterna stämmer innan ni slutför er beställning.",
    cart: {
        excludingVat: "ex moms",
        chilled: "Kyld",
        couldNotDeliverChosenToDate:
            "Markerade produkt(er) kan inte levereras till valt leveransdatum. Välj ett av följande alternativ:",
        changeDeliveryDate: "Ändra leveransdatum",
        containCountProducts: "Din varukorg innehåller {{count}} produkt",
        containCountProducts_plural: "Din varukorg innehåller {{count}} produkter",
        hideCartProducts: "Göm produkter i varukorg",
        frozen: "Fryst",
        placeOrder: "Lägg beställning",
        removeProductsThatCannotBeDelivered: "Ta bort alla produkter som inte kan levereras",
        selectedDeliveryDate: "Valt leveransdatum",
        shipping: {
            banner: {
                total: "Frakt",
                frozen: "Fryst",
                chilled: "Kyld",
            },
            free: {
                title: "Fri frakt",
                values: {
                    total: "För ordrar över {{thresholdFree}}",
                    frozen: "Fryst: order > {{thresholdFree}}",
                    chilled: "Kyld: order > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Din beställning kan inte levereras gratis eftersom den inte når gränsen på {{thresholdFree}}. En leveransavgift på {{charge}} tillkommer. För gratis leverans, lägg till {{remainingCost}}!",
                belowMin:
                    "Din beställning når inte minsta beställning på {{thresholdMin}}. Lägg till minst {{remainingCost}} av varor för att låsa upp leveransen!",
            },
            total: {
                total: "Frakt Totalt",
                frozen: "Fryst",
                chilled: "Kyld",
            },
        },
        showCart: "Visa varukorg",
        showCartProducts: "Visa produkter i varukorg",
        totalShipping: "Total frakt:",
        title: "Varukorg",
        vat: "Moms ({{percentage}})",
        weight: {
            "product-types": "Vikt Salad Bar / Deli / Food To Go",
            "frozen": "Vikt Chilled / Frozen",
            "no-split": "Vikt",
        },
    },
    creditFreeze: {
        title: "Viktig information",
        body:
            "Din butiks Picadeli-konto har obetalda fakturor som måste hanteras innan du kan lägga beställningar igen.\n" +
            "\n" +
            "Kontakta Picadelis kundsupport för att lösa utestående fakturor och få tillgång till beställning igen.\n" +
            "\n" +
            "Ring: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Saknar leveranskalender",
        title: "Leveransinformation",
        myDeliveryCalendar: "Min leveranskalender",
        orderRef: "Referens: {{orderRef}}",
        orderRefLabel: "Ange referens (valfritt)",
        selectedDeliveryDate: "Valt leveransdatum",
        pickDeliveryDate: "Välj datum",
        timeLeftToOrder: "Tid kvar att beställa",
        hideAdvancedOptions: "Göm avancerade alternativ",
        showAdvancedOptions: "Visa avancerade alternativ",
        zeroPriceOrder: "Nollpris-order",
        adminCalendar: "Adminkalender",
        adminCalendarAnyDeliveryDate: "Förbigå leveranskalendern (FLS mode)",
        adminCalendarWarning:
            "Var försiktig. Använd bara det här alternativet om du vet vad du håller på med!",
        adminCalendarDeliveryDateWarning:
            "OK, dags för ytterligare en varning: Om du väljer ett datum i det här läget kan du INTE se stängda produkter OCH beställningen kommer troligt orsaka ett felmeddelande i EDI inkommande beställningar i Jeeves.",
        table: {
            header: {
                deliveryDate: "Leveransdatum",
                assortment: "Sortiment",
                lastOrderDeadline: "Sista ordertidpunkt",
                onTheWay: "På väg",
            },
            btn: {
                select: "Välj",
                selectAndCreateOrderProposal: "Välj och skapa orderförslag",
            },
            orderSuggestionAvailable: "Orderförslag tillgängligt",
        },
    },
    filter: {
        showAllProducts: "Alla produkter",
        showPlanogramProducts: "Planogramsprodukter",
        showFavorites: "Visa favoriter",
        showAccessories: "Tillbehör",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Lägg orderförslag i kundvagnen",
        replaceCartDialog: {
            content:
                "Om du lägger till orderförslaget i din kundvagn kommer produkter som redan ligger i kundvagnen att plockas bort.",
            title: "Ersätt kundvagn",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Din beställning är nu mottagen.",
        thankYouForYourPurchase: "Tack för ditt köp",
        yourOrderHasNowBeenReceived: "Din beställning är nu mottagen",
        continueShopping: "Fortsätt handla",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Kunde inte lägga order.",
        removeInvalidProductsBtn: "Ta bort ogiltiga produkter",
    },
    planogramPdfBtn: "Planograms-PDF",
    productAlreadyInCart: "Finns redan i varukorgen",
    productAlreadyInList: "Beställ i produktlistan",
    productHasBeenReplaced: "Du har ersatt {{productName}}",
    productTable: {
        articleNo: "artnr",
        comparisonPrice: "jmfpris",
        comparisonPriceNonFood: "jmfpris",
        dataSheet: "Produktdatablad ver. {{version}}",
        icon: {
            frozen: "Fryst",
            inPlanogram: "I planogram",
            orderAssortment: "Beställningssortiment",
            news: "Nyhet",
        },
        name: "namn",
        onTheWay: "på väg",
        pricePerUnit: "pris/enh",
        selectRotationProduct: "Byt",
        selectPriceListProduct: "Välj",
        unit: "enhet",
    },
    productMenu: {
        orderHelpMeals: "Beställningshjälp måltider",
        orderSuggestionsMeals: "Orderförslag måltider",
        orderSuggestionsMealsDescription:
            "Här syns vilken mängd av varje produkt som behövs till de antal måltider ni valt. I kolumnen Över-/underskott syns hur mycket det blir över eller saknas. OBS glöm inte artiklar till lösviktsförsäljningen.",
        pricePerPortion: "Pris / Portion",
        numPackages: "Antal förpackningar",
        numPortions: "Antal portioner",
        perPortion: "Per portion",
        excessWeightTitle: "Överskott / underskott",
        meals: "Måltider",
        mealsDescription:
            "Beställ antal önskade portioner per rätt. Om du beställer flera måltider innehållande tex potatismos slås mängden ihop för att minimera ev svinn.",
        createOrderSuggestion: "Skapa orderförslag",
        someProductsCannotBeDelivered:
            "En eller flera produkter i denna måltid kan inte levereras. Klicka för mer information.",
    },
    searchProduct: "Sök på artikelnummer eller namn",
    selectAReplacement: "Välj ersättningsprodukt",
    thresholdWarningDialog: {
        pleaseCheck:
            "Vänligen kontrollera att antalet är det önskade innan ni slutför er beställning.",
        title: "Ovanligt stort antal produkter",
        weHaveNoticed:
            "Vi noterar att antalet av nedanstående produkt(er) som ni lagt i varukorgen är ovanligt stort:",
    },
    unavailableRotationProducts: "Stängd produkt",
};
